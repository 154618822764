import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';

import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import WorkIcon from '@mui/icons-material/Work';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { AppBar, Avatar, Fab } from '@mui/material';
import appIcon from "../Images/Logo.jpeg"
import { Call } from '@mui/icons-material';

const drawerWidth = 240;




const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export  function MMenubar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box >
      <CssBaseline />
      <AppBar position="fixed" open={open} className='appbar'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
           <span className='bold'>  <Avatar onClick={()=>{
                window.location.href="home"
           }} src={appIcon} sx={{ width: 56, height: 56 }} alt="Phonenix Care"/>  </span>
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div className='right'>
          <a  href='tel:+44 7949 018669'>
          <Fab  className='call' size='small'> 
            <Call/>
          </Fab>          
          </a>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem>
          
            <ListItemButton>
                <ListItemIcon>
                        <HomeIcon/>
                </ListItemIcon>
                <ListItemText>
                   <a className='black' href={`${window.location.origin}/home`}>Home </a> 
                </ListItemText>
            </ListItemButton>
          </ListItem>
          
          <ListItem>
          
          <ListItemButton>
              <ListItemIcon>
                <InfoIcon/>
              </ListItemIcon>
              <ListItemText>
                   <a className='black' href={`${window.location.origin}/aboutus`}>  About Us</a>
              </ListItemText>
          </ListItemButton>
        </ListItem>
        
        
        <ListItem>
          
          <ListItemButton>
              <ListItemIcon>
                    <MedicalInformationIcon/>
              </ListItemIcon>
              <ListItemText>
                <a className='black' href={`${window.location.origin}/careservices`}>Care Services</a>
              </ListItemText>
          </ListItemButton>
        </ListItem>
        
        <ListItem>
          
          <ListItemButton>
              <ListItemIcon>
                <WorkIcon/>
              </ListItemIcon>
              <ListItemText>
                <a className='black' href={`${window.location.origin}/jobsearch`}>Job Search</a>
              </ListItemText>
          </ListItemButton>
        </ListItem>
        
        
        <ListItem>
          
          <ListItemButton>
              <ListItemIcon>
                <ContactMailIcon/>
              </ListItemIcon>
              <ListItemText>
               <a className='black' href={`${window.location.origin}/contactus`}> Contact Us </a> 
              </ListItemText>
          </ListItemButton>
        </ListItem>
        </List>
        <Divider />
        
      </Drawer>
    </Box>
  );
}
