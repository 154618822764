import { Container, Grid, List, ListItem, Paper } from "@mui/material";
import enablement1 from "../../Images/enablement1.jpg"
import enablement2 from "../../Images/enablement2.jpg"
import DotIcon from '@mui/icons-material/FiberManualRecord';

export function Enablement(){

    return (
    
            <Container fixed>
                    <Paper className="paper">   
                    
                    <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <p className="bold ">
                                Promoting Independence Through Enablement and Reablement at Phoenix Care 4 U:
                                </p>
                                <p className="text">
                                At Phoenix Care 4 U, we understand that relying on others for day-to-day tasks can be disheartening. The good news is that there's a way forward! Our professional enablement and reablement services, offered under the Phoenix Care 4 U name, are designed to help you build the confidence and essential skills needed to regain your independence.
                                </p>
                                <p className="text">
                                Whether you've encountered challenges due to aging, disability, illness, or an unexpected accident, we're here to support you. Tasks such as cooking, bathing, cleaning, and more can become achievable once again with our assistance.
                                </p>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                    <img width={"100%"} height={"100%"} src={enablement1} alt="Enablement and Reablement"/>
                                    <br/><br/>
                            </Grid>
                        </Grid>
                        </Paper>
                        <br/>
                        <Paper className="paper">
                    <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                        <img width={"100%"} height={"100%"} src={enablement2} alt="Enablement and Reablement"/>
                                        <br/><br/>
                                    
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                    <p className="bold">
                                    Our dedicated carers work closely with you to promote independence in various daily activities, including:
                                    </p>
                                    <List className="item">
                                        <ListItem>
                                            <DotIcon color="primary"/>  Preparing Meals
                                        </ListItem>
                                        <ListItem>
                                            <DotIcon color="primary"/> Dressing and Undressing
                                        </ListItem>
                                        <ListItem>
                                            <DotIcon color="primary"/>Bathing, Washing, and Personal Care
                                        </ListItem>
                                        <ListItem>
                                            <DotIcon color="primary"/> Medication Management
                                        </ListItem>
                                        <ListItem>
                                            <DotIcon color="primary"/> Cleaning
                                        </ListItem>
                                        <ListItem>
                                            <DotIcon color="primary"/> Navigating Your Home
                                        </ListItem>
                                        <ListItem>
                                            <DotIcon color="primary"/> Performing Prescribed Exercises
                                        </ListItem>
                                    </List>
                            </Grid>
                            </Grid>
                    </Paper>
                    
            </Container>
    )
}