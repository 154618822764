import { useEffect } from "react"

export function PageNotFound(){
    
    useEffect(()=>{
        window.location.href="/home"
    },[])
    return (
            <div>
            </div>
    )
}