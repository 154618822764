import { Grid,Container,Paper } from "@mui/material";
import service1 from "../Images/service1.jpg"
import service2 from "../Images/service2.jpg"
import service3 from "../Images/service3.jpg"
import service4 from "../Images/service4.jpg"
import service5 from "../Images/service5.jpg"
import service6 from "../Images/service6.jpg"
import { Fade } from "react-awesome-reveal";

import { Footer } from "../Component/Footer";
export function CareService(){
    
    
    return (
            <Container fixed>
                    <br/><br/>
                    <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Fade>
                                <Paper className="paper">
                                <a href="personalcare" className="service"> 
                                <img src={service1} width={"100%"} height={"100%"} alt=" Personal Care and Support Services"/>
                                <p className="text">
                                   Personal Care and Support Services
                                </p>
                                </a>
                                </Paper>
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Fade>
                                <Paper className="paper">
                                <a href="enablement-and-reablement" className="service">
                                <img src={service2} width={"100%"} height={"100%"} alt="Enablement and Reablement"/>
                                <p className="text" >
                               Enablement and Reablement
                                </p>
                                </a>
                                </Paper>
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Fade>
                                <Paper className="paper">
                                <a href="respitecare" className="service">
                                <img src={service3} width={"100%"} height={"100%"} alt="Respite Care"/>
                                <p className="text">
                               Respite Care
                                </p>
                                </a>
                                </Paper>
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Fade>
                                <Paper className="paper">
                                <a href="complexcare" className="service">
                                <img src={service4} width={"100%"} height={"100%"} alt=" Personal Care and Support Services"/>
                                <p className="text">
                                Complex Care
                                </p>
                                </a>
                                <br/>
                                </Paper>
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Fade>
                                <Paper className="paper">
                                <a href="24-hour-care" className="service">
                                <img src={service5} width={"100%"} height={"100%"} alt=" Personal Care and Support Services"/>
                                <p className="text">
                                24 Hour Care
                                </p>
                                </a>
                                </Paper>
                                </Fade>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Fade>
                                <Paper className="paper">
                                <a href="supporting-individuals-with-learning-disability" className="service">
                                <img src={service6} width={"100%"} height={"100%"} alt=" Personal Care and Support Services"/>
                                <p className="text">
                               Supporting Individuals with Learning Disability
                                </p>
                                </a> 
                                </Paper>
                                </Fade>
                            </Grid>
                    </Grid>
                    <br/>
                    <br/>
            </Container>
    )
}