import { Container, Grid, List, ListItem, Paper } from "@mui/material";
import complexcare1 from "../../Images/complexcare1.jpg"
import complexcare2 from "../../Images/complexcare2.jpg"
import DotIcon from '@mui/icons-material/FiberManualRecord';

export function ComplexCare(){
    return (
            <Container fixed>
                    <Paper className="paper">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <p className="bold">
                            Complex Care:
                            </p>
                            <p className="text">
                            Complex care is a specialized level of support designed to assist individuals in managing intricate health and medical conditions. These conditions may encompass chronic or long-term health issues, terminal illnesses, life-limiting illnesses, or specialized medical conditions demanding additional and intensive care and support for the management of symptoms and day-to-day activities.
                            </p>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <img src={complexcare1} width={"100%"} height={"100%"} alt="Complex care " />
                        </Grid>
                        </Grid>
                    </Paper>
                    <br/>
                    <Paper className="paper">
                    
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <img src={complexcare2} width={"100%"} height={"100%"} alt="Complex care " />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className="bold">
                            Our Complex Care Services at Phoenix Care 4 U:
                            </p>
                            <List className="item">
                                <ListItem><DotIcon color="primary"/> Tracheostomy Care</ListItem>
                                <ListItem><DotIcon color="primary"/> Oral and Tracheal Suctioning</ListItem>
                                <ListItem><DotIcon color="primary"/>Oxygen Therapy </ListItem>
                                <ListItem><DotIcon color="primary"/>Epilepsy Rescue Medication (Buccal Midazolam & Rectal Diazepam)</ListItem>
                                <ListItem><DotIcon color="primary"/>PEG (Percutaneous endoscopic gastrostomy) and JEJ (Jejunostomy) </ListItem>
                                <ListItem><DotIcon color="primary"/> NG (Nasogastric) Tube Care</ListItem>
                                <ListItem><DotIcon color="primary"/>Ileostomy (Stoma/Colostomy) Support</ListItem>
                                <ListItem><DotIcon color="primary"/>Complex Bowel Management </ListItem>
                                <ListItem><DotIcon color="primary"/>Cough Assist and Assisted Coughing </ListItem>
                                <ListItem><DotIcon color="primary"/> Ventilation Care</ListItem>
                            </List>
                        </Grid>
                    </Grid>
                    </Paper>
                    
            </Container>
    )
}