import { Container, Grid, List, ListItem, Paper } from "@mui/material";
import service1 from "../../Images/service1.jpg"
import personalCare1 from "../../Images/personalCare1.jpg"
import personalCare2 from "../../Images/personalCare2.jpg"
import personalCare3 from "../../Images/personalCare3.jpg"
import DotIcon from '@mui/icons-material/FiberManualRecord';
export function PersonalCare(){
    
    return (
            <Container fixed>
                    <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                            <p className="bold center">
                            Personal Care and Support Services
                            </p>
                            <p className="text">
                            At Phoenix Care 4 U, our paramount mission is to empower you to maintain the lifestyle you cherish. We place you firmly in control, delivering home care and support that aligns precisely with your preferences and schedule. We understand that selecting the right care is a significant decision, and the prospect of relocating to a nursing home can be unsettling.
                            </p>
                            <p className="text">
                            As our valued client, you can rest assured that we are dedicated to enabling you to continue living a high-quality, independent life within the comfort of your own home. We offer a cost-effective and efficient solution for individuals who wish to remain in their homes while requiring assistance with day-to-day living.
                            </p>
                            </Grid>
                            </Grid>
                            <Paper className="paper">
                    <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <img width={"100%"} height={"100%"}  src={personalCare1} alt="Personal Care"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <p className="text">
                                We recognize and celebrate the uniqueness of each person we support, with their individual needs, desires, and aspirations for the future. Whether you seek assistance with bathing and dressing, support for medication management, help with housework, shopping, or access to your local community, we are here to provide you with the care and support you require. Our care packages are entirely tailored to your specifications, allowing you to have as much or as little input as you desire, along with the involvement of your family members.
                                </p>
                            </Grid>
                            </Grid>
                            </Paper>
                            <br/>
                            <Paper className="paper">
                            
                    <Grid container spacing={1}>
                            
                            <Grid item xs={12} sm={6}>
                                <p className="bold">Our Widely Requested Services:</p>
                                <List className="item">
                                    <ListItem><DotIcon color="primary"/> Covering dressing, washing, bathing, and grooming</ListItem>
                                    <ListItem><DotIcon color="primary"/> Providing dignified assistance for your comfort. </ListItem>
                                    <ListItem><DotIcon color="primary"/> Emphasizing healthy eating, including meal preparation and support. </ListItem>
                                    <ListItem><DotIcon color="primary"/>Ensuring safe and precise medication management.</ListItem>
                                    <ListItem><DotIcon color="primary"/> Prioritizing your safety and independence. </ListItem>
                                    <ListItem><DotIcon color="primary"/> Employing trained techniques for your comfort. </ListItem>
                                    <ListItem><DotIcon color="primary"/>  Comprehensive care to promote your well-being. </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <img width={"100%"} height={"100%"}  src={personalCare2} alt="Personal Care"/>
                                <br/><br/>
                                <br/>
                            </Grid>
                            </Grid>
                            </Paper>
                            <br/>
                            
                            <Paper className="paper">
                    <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                    <img width={"100%"} height={"100%"}  src={personalCare3} alt="Personal Care"/>
                                    <br/><br/>
                                    <br/><br/>
                                    <br/>
                                    
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <p className="bold">Additional Support Services:</p>
                                <List className="item">
                                    <ListItem><DotIcon color="primary"/>shopping Assistance </ListItem>
                                    <ListItem><DotIcon color="primary"/>Cooking  </ListItem>
                                    <ListItem><DotIcon color="primary"/> Local Community Visits </ListItem>
                                    <ListItem><DotIcon color="primary"/>Pet Care </ListItem>
                                    <ListItem><DotIcon color="primary"/> Light Domestic Duties/Ironing</ListItem>
                                    <ListItem><DotIcon color="primary"/> Companionship</ListItem>
                                    <ListItem><DotIcon color="primary"/> Assistance with Reading, Writing, and Correspondence </ListItem>
                                </List>
                            </Grid>
                    </Grid>
                    </Paper>
                    
            </Container>
    )
}