import { Container, Grid, List, ListItem, Paper } from "@mui/material";
import hourcare1 from "../../Images/hourcare1.jpg"
import hourcare2 from "../../Images/hourcare2.jpg"
import DotIcon from '@mui/icons-material/FiberManualRecord';

export function HourCare(){
    
    
    return (
        <Container >
            <Paper className="paper">
            <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <img width={"100%"} height={"100%"} src={hourcare1} alt="24 Hour care "/>
                        <br/><br/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                            <p className="bold">
                            24 Hour Care:
                            </p>
                            <p className="text">
                            At Phoenix Care 4 U, we understand that there are times when individuals require a higher level of support and care, whether it's for a short-term or long-term duration. A thorough assessment helps determine the precise nature of your needs, enabling us to identify where interventions are most necessary to help you achieve your desired outcomes.
                            </p>
                    </Grid>
                </Grid>
                </Paper>
                <br/>
                <Paper className="paper">
            <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <img width={"100%"} height={"100%"} src={hourcare2} alt="24 Hour care "/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                            <p className="bold">
                            You may be recovering from illness, or a serious injury, or you may have an ongoing medical condition that requires constant surveillance. Our response will always depend on your needs. Typically, it will be one of the following options:
                            </p>
                            <List className="item">
                                <ListItem><DotIcon color="primary"/> We offer support for several hours to assist with daily living activities and help you organize your day when you are alone.</ListItem>
                                <ListItem><DotIcon color="primary"/>Our live-in service provides you with the reassurance of having someone residing in your home, ensuring your complete peace of mind.</ListItem>
                                <ListItem><DotIcon color="primary"/>For continuous care, we provide a dedicated team of Care Support Workers who offer around-the-clock coverage in shifts of 8 hours each. This consistency allows for the development of a familiar and supportive relationship. </ListItem>
                                <ListItem><DotIcon color="primary"/> Our night support service ensures that you have a Care Support Worker at your home every night, providing added security and assistance during nighttime hours.</ListItem>
                                
                            </List>
                            <p className="text">
                            Our response to your needs will always be tailored to your specific requirements. At Phoenix Care 4 U, we are committed to providing you with the level of care and support that best suits your situation, ensuring your comfort and well-being around the clock.
                            </p>
                    </Grid>
            </Grid>
            </Paper>
        </Container>
    )

}