import { Container, FormControl, Grid,InputLabel,Paper,Input, Button } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import appLogo from "../Images/Logo.jpeg"

export function Job(){
    
    
    const handleSubmit=()=>{
                let div=document.getElementById("submit")
                if(div){
                        div.click()
                }
    }
    
    return (
            <Container>
                    <Fade>
                    <Paper className="paper">
                    <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                    <img src={appLogo} alt="App Logo" width={"100%"} height={"100%"}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                    <span className="bold text">
                                    To join our team, please send an email to  info@phoenixcare4u.co.uk
                                    </span>
                                    
                                    <h2>
                                    OR
                                    </h2>
                                    <Paper className="paper">
                                        <form  action="mail.php" method="post" enctype="multipart/form-data">
                                            <FormControl required fullWidth className="element">
                                                    <InputLabel>
                                                        Name
                                                    </InputLabel>
                                                    <Input type="text" id="name" name="name"/>
                                            </FormControl>
                                            <FormControl required fullWidth className="element">
                                                    <InputLabel>
                                                            Email
                                                    </InputLabel>
                                                    <Input type="email" id="email" name="email"/>
                                            </FormControl>
                                            
                                            <FormControl required fullWidth className="element">
                                                    <p>Resume</p>
                                                    <Input type='file' id="file" name="resume"/>
                                            </FormControl>
                                            <FormControl required fullWidth className="element">
                                                    <Button onClick={handleSubmit} variant="contained">
                                                            SUBMIT
                                                    </Button>
                                            </FormControl>
                                            <input type="submit" className="hide" value="Submit" id="submit" name="submit">
                                            
                                            </input>
                                            </form>
                                    </Paper>
                            </Grid>
                    </Grid>
                    </Paper>
                    </Fade>
                    <br/><br/>
            </Container>
    )
}