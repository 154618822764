import { Container, Grid, List, ListItem, Paper } from "@mui/material";
import respiteCare1 from "../../Images/respitecare1.jpg"
import respiteCare2 from "../../Images/respitecare2.jpg"
import respiteCare3 from "../../Images/respitecare3.jpg"
import DotIcon from '@mui/icons-material/FiberManualRecord';
export function RespiteCare(){
    
    return (
            <Container fixed>
                    <Paper className="paper">
                        <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                        <img width={"100%"} height={"100%"} src={respiteCare1} alt="Respite care "/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                        <p className="bold">Respite Care:</p>
                                        <p className="text">
                                        In the United Kingdom, a significant portion of care is provided by devoted family members – sons and daughters caring for aging parents, spouses tending to their partners, or extended family members stepping in to support. Respite care services at Phoenix Care 4 U offer a short-term solution, providing essential care and support to relieve regular caregivers, affording them the rest and rejuvenation they deserve.
                                        </p>
                                </Grid>
                    </Grid>
                    </Paper>
                    <br/>
                    <Paper className="paper">
                    <Grid container spacing={1}>
                    
                                <Grid item xs={12} sm={6}>
                                        <p className="text">
                                        Our respite care services ensure that families can plan a break or even a well-deserved vacation, knowing their loved one is receiving the same level of care and compassion they provide themselves. Opting for in-home respite care minimizes disruption, allowing your loved one to remain in their familiar home and community, surrounded by the comforts they cherish. Whether you need full-time live-in care or regular hourly support with someone available when you need them, we offer flexible and tailored respite care options. These can be planned in advance or arranged at short notice to provide regular breaks for families. We provide both live-in and visiting respite care, including emergency respite care or planned, short-term breaks.
                                        </p>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                        <img width={"100%"} height={"100%"} src={respiteCare2} alt="Respite care "/>
                                </Grid>
                        </Grid>
                        </Paper>
                        <br/>
                        <Paper className="paper">
                    <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                        <img width={"100%"} height={"100%"} src={respiteCare3} alt="Respite care "/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                        <p className="bold">
                                        Our Respite Care Services at Phoenix Care 4 U:
                                        </p>
                                        <List className="item">
                                            <ListItem><DotIcon color="primary"/> Medication Management</ListItem>
                                            <ListItem><DotIcon color="primary"/>Mobility Support </ListItem>
                                            <ListItem><DotIcon color="primary"/> Personal care</ListItem>
                                            <ListItem><DotIcon color="primary"/>Companionship and Sitting Services </ListItem>
                                            <ListItem><DotIcon color="primary"/>Shopping Assistance </ListItem>
                                            <ListItem><DotIcon color="primary"/>General Welfare Checks </ListItem>
                                            <ListItem><DotIcon color="primary"/>Household Tasks</ListItem>
                                        </List>
                                </Grid>
                        </Grid>
                    </Paper>
            </Container>
    )
}