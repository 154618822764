import { Container, Grid, Paper } from "@mui/material";
import supporting from "../../Images/supporting.jpg"
export function Supporting(){
    
    return (
            <Container fixed>
                <Paper className="paper">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                                <img src={supporting} width={"100%"} height={"100%"} alt="Complex care " />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <p className="bold">
                            Supporting Individuals with Learning Disability:
                            </p>
                            <p className="text">
                            At Phoenix Care 4 U, we are dedicated to ensuring that individuals with learning disabilities have the opportunity to lead fulfilling, empowered lives, making independent choices and living with increased autonomy. We provide the highest level of care and support for individuals living with mild, moderate, or severe learning disabilities.
                            </p>
                            <p className="text">
                            Learning disabilities can affect people of all ages and manifest differently from one person to another. Regardless of the challenges you may face, you can trust us to provide you with the best possible support. We firmly believe that it is the community's responsibility, not the learning disability, that may have hindered your ability to make choices and embrace new challenges.
                            </p>
                            <p className="text">
                            Our approach to care is deeply individualized, tailored to your unique likes, dislikes, preferences, and aspirations. For us, your well-being is the ultimate priority, and we are committed to empowering you to lead a life that aligns with your individuality and goals. At Phoenix Care 4 U, it's all about you, and we're here to support your journey towards greater independence and happiness.
                            </p>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
    )
}