import { Container, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
export function Footer(){
    
    return (
            <div className="footer">
            <Container>
            <Grid container spacing={1} >
                <Grid item xs={12} sm={3}>
                    <p className="bold white">Quick Links</p>
                    <ul>
                        <li className='li'><a href={"home"} > Home</a></li>
                        <li className='li'> <a href={"aboutus"} > About Us</a></li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <p className="bold white">Other Pages</p>
                    <ul>
                        <li className='li'><a href={"careservices"} > Care Service</a></li>
                        <li className='li'><a href={"jobsearch"} >Jobs</a></li>
                        <li className='li'> <a href={"contactus"} >Contact Us</a> </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <p className="bold white">Contact Info</p>
                    <ul >
                        <li className='li flex'><span><LocationOnIcon/>  </span>  <span> Olympic House, 28-42 Clements Road, Ilford, England, IG1 1BA </span></li>
                        <li className='li flex'> 
                        
                        <a href="tel:+44 7949 018669" className="flex"
                        ><span><CallIcon/> </span> <span>+44 7949 018669</span></a>
                        </li>
                        <li className='li flex'> 
                        <a href="tel:+44 7380 932036" className="flex"><span><CallIcon/> </span> <span>+44 7380 932036</span></a> 
                        
                        </li>
                        <li className='li flex' > <a  className='flex' href="mailto:info@phoenixcare4u.co.uk"> <span> <EmailIcon/> </span> <span> &nbsp; info@phoenixcare4u.co.uk </span></a> </li>
                    </ul>
                </Grid>
                <Grid item xs={12} sm={3}>
                <div>
                    <p className="bold"> Copyright © 2023.  Phoenix Care 4 U  </p>
                    <p> <a href={`${window.location.origin}/privacy`}> Privacy Policy </a> </p>
                </div>
                </Grid>
            
            </Grid>
            </Container>
            </div>
    )
}