import { Grid,Container,Paper} from "@mui/material";
import about1 from "../Images/about1.jpg"
import about2 from "../Images/about2.jpg"

import { Fade } from "react-awesome-reveal";
import DiamondIcon from '@mui/icons-material/Diamond';
import { Footer } from "../Component/Footer";


export function About(){
    
    
    return (
            <Container fixed>
                    <Paper className="paper">
                    <Grid container spacing={1}>
                    <Grid item xs={12} sm={12}>
                    <h1>About Us</h1>
                            <p className="text">
                            Phoenix Care 4 U stands as a distinguished nursing and care agency, boasting a nationwide network of highly skilled Nurses, Healthcare Assistants, and Support Workers spanning the UK. Our prominence as one of the UK's swiftest-growing care providers is founded on an unyielding commitment to excellence, our steadfast adherence to the essential care standards mandated for operation within the UK's healthcare sector.
                            </p>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <img src={about1} alt="about us" width="100%" height={"100%"}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                            <Fade>
                            <p className="text">
                            Our repertoire of care services at Phoenix Care 4 U is comprehensive and tailored to fulfill a myriad of care needs. From brief, on-demand visits to comprehensive 24-hour care, Live-in Care, and specialized care, our multifaceted offerings cater adeptly to a broad spectrum of requirements. Furthermore, we extend our dedication to esteemed care homes and hospitals by provisioning them with a dedicated cadre of qualified care staff and nurses.
                            </p>
                            </Fade>
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                            <Fade>
                            
                            <p className="text">
                            Our foremost mission is centered on delivering a value-focused service that remains unwaveringly reliable and responsive to the needs of our clients, Service Users, and their families. At Phoenix Care 4 U, our compass is guided by the unwavering principle of prioritizing quality over profit, ensuring the well-being and contentment of those we serve always hold precedence.
                            </p>
                            <p className="text">
                            As a forward-thinking organization, we perpetually seek innovative methodologies to elevate the quality of our services. Our commitment to going the extra mile is a testament to our dedication to placing our clients and associates at the pinnacle of our priorities. In all our business dealings, we uphold the virtues of honesty, fairness, and unwavering integrity. Your well-being remains our steadfast focal point, and we pledge our unwavering dedication to delivering the zenith of care standards.
                            </p>
                            </Fade>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <img src={about2} alt="about us" width="100%" height={"100%"}/>
                    </Grid>
                    </Grid>
                    </Paper>
                    <br/><br/>
            </Container>
    )
}