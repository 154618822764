import { Container, FormControl, Grid,InputLabel,Paper,Input, Button } from "@mui/material";
import appLogo from "../Images/project.png"
import { Fade } from "react-awesome-reveal";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import EmailIcon from '@mui/icons-material/Email';
export function Contact(){
    
        const handleSubmit=()=>{
                let div=document.getElementById("submit")
                if(div){
                        div.click()
                }
    }
    
    
    return (
        <Container>
        <Fade>
        <Paper className="paper">
        <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                <span className="bold">
                                    OUR OFFICE ADDRESS
                                    </span>
                                    <br/>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.591638912184!2d0.06935757662308795!3d51.55738667182469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a68aa21e4517%3A0xb873cdf1dbd50149!2sOLYMPIC%20HOUSE!5e0!3m2!1sen!2sin!4v1696841757219!5m2!1sen!2sin" width={"100%"} height="450" style={{'border':'0'}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
                                <br/>
                                <div>
                                 
                                    <span>
                                       <LocationOnIcon/> &nbsp;Olympic House, 28-42 Clements Road, Ilford, England, IG1 1BA
                                    </span>
                                    <br/><br/>
                                    <span>
                                    <a href="tel:+44 7949 018669" className="black">
                                    <ContactPhoneIcon/>&nbsp;&nbsp;+44 7949 018669
                                    </a>
                                    </span>
                                    <br/><br/>
                                    <span>
                                    <a href="tel:+44 7380 932036" className="black">
                                    <ContactPhoneIcon/>&nbsp;&nbsp;+44 7380 932036
                                    </a>
                                    </span>
                                    <br/><br/>
                                    
                                    <span>
                                    <a href="mailto:info@phoenixcare4u.co.uk" className="black">
                                    <EmailIcon/> &nbsp; &nbsp;info@phoenixcare4u.co.uk
                                    </a>
                                    </span>
                                </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                        <br/><br/>
                        <span className="bold text">
                        If you have a question or a comment, please email us at info@phoenixcare4u.co.uk
                        </span>
                        
                        <h2>
                        OR
                        </h2>
                        <Paper className="paper">
                        <form action="contact.php" method="post" enctype="multipart/form-data">
                                <FormControl required fullWidth className="element">
                                        <InputLabel>
                                            Name
                                        </InputLabel>
                                        <Input type="text"  name="name"/>
                                </FormControl>
                                <FormControl required fullWidth className="element">
                                        <InputLabel>
                                                Email
                                        </InputLabel>
                                        <Input type="email"  name="email"/>
                                </FormControl>
                                <FormControl required fullWidth className="element">
                                        <InputLabel>
                                                Message
                                        </InputLabel>
                                        <Input type='text' multiline id="message" name="message"/>
                                </FormControl>
                                <FormControl required  className="element">
                                        <Button onClick={handleSubmit} variant="contained">
                                                SUBMIT
                                        </Button>
                                </FormControl>
                                <input type="submit" className="hide" value="Submit" id="submit" name="submit"/>
                                
                                </form>
                        </Paper>
                </Grid>
        </Grid>
        </Paper>
        </Fade>
        <br/><br/>
</Container>
    )

}