import { Container, Grid, Paper } from "@mui/material";

export function Privacy(){

    return (
            <Container fixed>
                    <Paper className="paper">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <h1>
                            Privacy Policy
                            </h1>
                            <p>
                            This privacy policy outlines how Phoenix Care 4 U Limited (referred to as Phoenix Care 4 U) uses and safeguards the information you provide when using our website. We are dedicated to protecting your privacy, and any information collected will be used in accordance with this privacy statement. Phoenix Care 4 U may update this policy occasionally by updating this page, so please check it periodically to ensure your satisfaction. This policy became effective on [Date of Policy Initiation].
                            </p>
                            <p className="bold">
                            Information We Collect
                            </p>
                            <p>
                            We may collect limited information, including:
                                <ul>
                                    <li>- Name</li>
                                    <li>- Email Address</li>
                                    <li>- CV</li>
                                </ul>
                            </p>
                            <p className="bold">
                            How We Use the Information
                            </p>
                            <p>
                            We gather this information to better understand your needs and provide you with an improved service. This includes:
                            </p>
                            <p>
                                <li>- Internal Record Keeping</li>
                                <li>- Enhancing Our Services</li>
                                <li>- Periodically Contacting You via Calls, Texts, Emails, or Postal Mail About Information We Believe You May Find Interesting</li>
                                <li>- Data Utilization for Audit and Compliance Purposes</li>
                            </p>
                            <p className="bold">
                            Security
                            </p>
                            <p>
                            We are committed to maintaining the security of your information. To prevent unauthorized access or disclosure, we have established suitable physical, electronic, and managerial procedures to protect and secure the data we collect online. In the event of a data security breach, we will promptly report it to the Information Commissioner's Office (ICO).
                            </p>
                            <p className="bold">
                            Your Individual Rights
                            </p>
                            <p >
                            Your rights regarding your data include, but are not limited to:
                            <ul>
                                <li>- Right to Erasure (Sometimes Referred to as 'The Right to Be Forgotten')</li>
                                <li>- Right to Withdraw from Using Our Services</li>
                                <li>- Right to Lodge a Complaint with the ICO If You Believe We Have Not Protected Your Data</li>
                                <li>- Right to Object to Processing</li>
                                <li>- Right of Subject Access (Subject to a Potential Fee)</li>
                            
                            </ul>
                            </p>
                            
                            <p className="bold">
                            Data Retention
                            </p>
                            <p>
                            We retain all data for a minimum of 6 years, in accordance with legislative guidance, except in cases related to the defense, establishment, or exercise of legal claims.
                            </p>
                            <p className="bold">
                            Controlling Your Personal Information 
                            </p>
                            <p>
                            We will not sell, distribute, or lease your personal information to third parties unless we have your permission, it is in your legitimate interests, or we are required by law to do so. If you believe that any information we have is incorrect or incomplete, please contact your local branch. We will promptly rectify any inaccuracies found.
                            </p>
                            <p className="bold">
                            Cookie Usage
                            </p>
                            <p>
                            Cookies are small files placed on your computer's hard drive with your permission. They help analyze web traffic and inform you when you visit a particular site. Cookies enable web applications to respond to you as an individual, tailoring their operations to your preferences. We use traffic log cookies for statistical analysis purposes and remove the data from the system afterward. Overall, cookies help us provide you with a better website by monitoring your page preferences. You can choose to accept or decline cookies by modifying your browser settings. However, this may impact your ability to fully utilize the website.
                            </p>
                            <p className="bold">
                            Links to Other Websites
                            </p>
                            <p>
                            Our website may contain links to external sites. While we strive to provide quality links, we have no control over other websites. Consequently, we cannot be responsible for the protection and privacy of any information you provide when visiting these sites, as they are not governed by this privacy statement. Please exercise caution and review the privacy policies of external sites.
                            </p>
                            <p className="bold">
                            Contact Information
                            </p>
                            <p>
                            If you have any questions or concerns regarding our privacy policy, please contact us at info@phoenixcare4u.co.uk.
                            </p>
                        </Grid>
                    
                    </Grid>
                    </Paper>
            </Container>
    )
}