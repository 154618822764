import { Avatar, Button, Container,Fab,Grid, Paper } from "@mui/material";
import backgroundImage from "../Images/background1.png"
import service1Image from "../Images/img1.jpg"
import service2Image from "../Images/img2.png"
import service3Image from "../Images/img3.jpg"
import PersonalCareIcon from '@mui/icons-material/SupportAgent';
import EnableIcon from '@mui/icons-material/SettingsAccessibility';
import CareIcon from '@mui/icons-material/AlarmOn';
import RespiteIcon from '@mui/icons-material/AirlineSeatFlat';
import ComplexIcon from '@mui/icons-material/Engineering';
import SupportIcon from '@mui/icons-material/Accessible';
import { Footer } from "../Component/Footer";
import { Fade } from "react-awesome-reveal";

export function Home(){
    
    return(
                <Container fixed>
                    <Paper className="paper">
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12}>
                                        <div className="imgBox">
                                            <img height="auto" width="100%"  className="image-section zoom" src={backgroundImage} alt="Service 1"/>
                                        </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                            
                                        <h1 className="center">
                                        Welcome to Phoenix Care 4 U
                                        </h1>
                                        <p className="text">
                                        Your trusted source for high-quality care services. At Phoenix Care 4 U, we are dedicated to providing unparalleled care and support for you and your loved ones. Our team of compassionate professionals is committed to delivering excellence in every aspect of care.
                                        </p>                                        
                                        <p className="text">
                                            Whether you need Personal Care and Support Services, Enablement and Reablement, 24 hour Care, Respite Care, Complex Care, or Supporting Individuals with Learning Disability, we've got you covered. We understand that your well-being is of utmost importance, and we take pride in offering personalized, reliable, and compassionate care that exceeds your expectations.
                                        </p>
                                        <p className="text">
                                        Experience the difference with Phoenix Care 4 U. Your health and happiness are our top priorities, and we're here to provide the care and peace of mind you deserve. Contact us today to learn more about our services and how we can make a positive impact on your life.
                                        </p>
                                        <br/>
                                        <div className="center">
                                            <Button variant='contained' onClick={()=>{
                                                window.location.href="/contactus"
                                            }}>
                                                Contact Us
                                            </Button>
                                        </div>
                            </Grid>
                        </Grid>
                        </Paper>
                        <br/><br/>
                        <Fade>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <Paper className="paper center">
                                        <div>
                                            <img className="circle" width="100%" height="100%" src={service1Image} alt="High-Quality Personalised Care"/>
                                        </div>
                                        <p className="bold">
                                        High-Quality Personalised Care
                                        </p>
                                        <p className="text">
                                        Discover the epitome of high-quality personalized care at Phoenix Care 4 U. Our dedicated team is here to cater to your unique needs, ensuring your well-being is our top priority.
                                        </p>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper className="paper center">
                                        <div>
                                            <img className="circle" width="100%" height="100%" src={service2Image} alt=" Reliable & Friendly Carers"/>
                                        </div>
                                        <p className="bold">
                                        Reliable & Friendly Carers
                                        </p>
                                        <p className="text">
                                        Trust our dedicated professionals at Phoenix Care 4 U. With unwavering commitment, we prioritize your well-being, delivering exceptional care services that you can rely on.
                                        </p>
                                        <br/>
                                </Paper>
                                
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper className="paper center">
                                        <div>
                                            <img className="circle" width="100%" height="100%" src={service3Image} alt="Flexible Home Care Packages"/>
                                        </div>
                                        <p className="bold">
                                        Flexible Home Care Packages
                                        </p>
                                        <p className="text">
                                        Whether you require a brief 30-minute call or 24-hour care, we cater to both short and long-term needs. Your well-being is our priority.
                                        </p>
                                        <br/>
                                </Paper>
                            </Grid>
                        </Grid>
                        </Fade>
                        <br/><br/>
                        <Fade>
                        <Paper className="paper center">
                        <Grid container spacing={1}>
                                <Grid item xs={12}>
                                        <h1>Our Trusted Service</h1>
                                        <p className="text">
                                        Phoenix Care 4 U delivers tailored care solutions that prioritize your well-being and autonomy in the familiar environment of your home. We recognize the distinctiveness of every individual we serve and craft person-centred care strategies that align with your values and life choices. Our mission is to empower you to lead and define your care journey. It's your life and your care, and we believe it should unfold on your terms, ensuring a beneficial outcome. Key services we provide encompass:
                                        </p>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <a href="personalcare" className="service"> 
                                        <Fab  color="primary">
                                            <PersonalCareIcon/>
                                        </Fab>
                                        <p className="title">
                                         Personal Care and Support Services
                                        </p>
                                        </a>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <a href="enablement-and-reablement" className="service"> 
                                        <Fab color="primary" >
                                            <EnableIcon/>
                                        </Fab>
                                        <p className="title">
                                       Enablement and Reablement
                                        </p>
                                        </a>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <a href="24-hour-care" className="service">
                                        <Fab color="primary">
                                            <CareIcon/>
                                        </Fab>
                                        <p className="title">
                                       24 hour Care
                                       
                                        </p>
                                    </a>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                <a href="respitecare" className="service">
                                        <Fab color="primary">
                                            <RespiteIcon/>
                                        </Fab>
                                        <p className="title">
                                        Respite Care
                                        </p>
                                        </a>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <a href="complexcare" className="service">
                                        <Fab color="primary">
                                            <ComplexIcon/>
                                        </Fab>
                                        <p className="title">
                                        Complex Care
                                        </p>
                                    </a>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <a href="supporting-individuals-with-learning-disability" className="service"> 
                                        <Fab color="primary">
                                            <SupportIcon/>
                                        </Fab>
                                        <p className="title">
                                        Supporting Individuals with Learning Disability
                                        </p>
                                    </a>
                                </Grid>
                        </Grid>
                        </Paper>
                        </Fade>
                        <br/>
                        <br/>
                </Container>
    )
}