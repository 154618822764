import logo from './logo.svg';
import './App.css';
import {BrowserRouter,Routes,Route} from "react-router-dom"
import { Home } from './Layout/Home';
import { Menubar } from "./Component/Menubar";
import { About } from './Layout/About';
import { CareService } from './Layout/CareService';
import { Job } from './Layout/Job';
import { Contact } from './Layout/Contact';
import { PersonalCare } from './Layout/Services/PersonalCare';
import { Enablement } from './Layout/Services/Enablement';
import { RespiteCare } from './Layout/Services/RespiteCare';
import { ComplexCare } from './Layout/Services/ComplexCare';
import { HourCare } from './Layout/Services/24HourCare';
import { Supporting } from './Layout/Services/Supporting';
import { Footer } from './Component/Footer';
import { Privacy } from './Layout/Privacy';
import { isMobile } from 'react-device-detect';
import { MMenubar } from './Component/MMenubar';
import { PageNotFound } from './Layout/NotFound';

function App() {
  return (
      <BrowserRouter>
                {
                    isMobile?
                    <MMenubar/>
                    :
                    <Menubar/>
                }
        <br/><br/>
        <br/><br/>
        <br/><br/>
        
            <Routes>
                <Route element={<Home/>} path=""/>
                <Route element={<Home/>} path="home"/>
                <Route element={<About/>} path="aboutus"/>
                <Route element={<CareService/>} path="careservices"/>
                <Route element={<Job/>} path="jobsearch"/>
                <Route element={<Contact/>} path="contactus"/>
                <Route element={<PersonalCare/>} path="personalcare"/>
                <Route element={<Enablement/>} path="enablement-and-reablement"/>
                <Route element={<RespiteCare/>} path="respitecare"/>
                <Route element={<ComplexCare/>} path="complexcare"/>
                <Route element={<HourCare/>} path="24-hour-care"/>
                <Route element={<Supporting/>} path="supporting-individuals-with-learning-disability"/>
                <Route element={<Privacy/>} path="privacy"/>
                <Route element={<PageNotFound/>} path="*"/>
                
            </Routes>
            <Footer/>
            
      </BrowserRouter>
  );
}

export default App;
